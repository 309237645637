<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="12">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
            trash: true,
          }"
          :preloader="preloader"
          :optionsBranches="branchesOptions"
        />
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          dark
          fab
          bottom
          right
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="addDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Neue Vorlage anlegen</span>
    </v-tooltip>

    <v-dialog v-model="addDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 primary white--text">
          Neue Vorlage anlegen
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            v-model="isFormValid"
            v-on:submit.prevent="createNew"
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="branches_id"
                    :items="branchesOptions"
                    item-text="name"
                    item-value="id"
                    label="Branche"
                    hint="Die Branche kann später nicht verändert werden."
                    persistent-hint
                    :rules="[rules.required]"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Titel"
                    v-model="title"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="isFormValid"
            color="green"
            dark
            @click="createNew"
            :loading="fetchinData"
            >Vorlage anlegen</v-btn
          >
          <v-btn v-else disabled>Vorlage anlegen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      :dialog="deleteDialog"
      headline="Bist du dir sicher?"
      message="Möchtest du diesen Datensatz tatsächlich löschen? Das kann nicht rückgängig gemacht werden."
      @reject="deleteDialog = false"
      @accept="deleteItem"
    />

    <CustomDialog
      :dialog="customDialog"
      headline="Template kann nicht gelöscht werden."
      :message="customDialogMessage"
      @reject="customDialog = false"
    />
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import DataTable from "@/components/ui/DataTable.vue";
import ConfirmationDialog from "@/components/ui/ConfirmationDialog";
import CustomDialog from "@/components/ui/CustomDialog";
export default {
  created: function () {
    this.getData(); // call it immediatly
  },

  name: "Templates",

  components: {
    PageHeader,
    DataTable,
    ConfirmationDialog,
    CustomDialog,
  },

  data() {
    return {
      itemId: null,
      itemIndex: null,
      addDialog: false,
      deleteDialog: false,
      customDialog: false,
      customDialogMessage: null,
      isFormValid: false,
      branches_id: null,
      branchesOptions: [],
      title: null,
      pageTitle: "Jahresmarketing Vorlagen",
      pageDescription: "Hier werden Planvorlagen verwaltet.",
      edit: true,
      trash: true,
      copy: false,
      search: "",
      headers: [],
      items: [],
      preloader: false,
      rules: {
        required: (value) => !!value || "Pflichtfeld",
      },
    };
  },

  methods: {
    async createNew() {
      /* create new entry */

      var formData = new FormData();
      formData.append("branches_id", this.branches_id);
      formData.append("title", this.title);

      this.preloader = true;
      let response = await this.postRequest("planTemplates", "", formData);

      if (response.status === 200) {
        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$createdMessage;
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        this.addDialog = false;
        this.getData(); // refetch instead of mutate
      }

      this.preloader = false;
    },

    async getData() {
      this.preloader = true;

      let response = await this.getRequest("planTemplates");

      if (response.status === 200) {
        this.headers = [
          { text: "ID", value: "id" },
          { text: "Branche", value: "branchName" },
          { text: "Titel", value: "title" },
          { text: "Aktiv", value: "isActive" },
          { text: "Created", value: "created" },
          { text: "Actions", value: "actions", sortable: false },
        ];

        this.items = response.data.data.reverse();
        this.search = "";
      }

      /* fetch branches */
      let branchesResponse = await this.getRequest("products/branches");
      if (branchesResponse.status === 200) {
        this.branchesOptions = branchesResponse.data.data;
      }

      this.preloader = false;
    },

    async deleteItem() {
      let response = await this.deleteRequest("planTemplates/" + this.itemId);

      if (response.status === 200) {
        this.deleteDialog = false;
        this.getData(); // refetch instead of mutate

        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$deletedMessage;
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      } else if (response === 403) {
        this.deleteDialog = false;
        this.customDialog = true;
        this.customDialogMessage =
          "Dieses Template wird bereits von Plänen verwendet.";
      }
    },

    /* Emitted Actions:
    Hier werden die aus der <DataTable></DataTable> Komponente 'emittete' Werte verarbeitet. 
    Die Komponente übermittelt die Button-Klicks ... editItem etc.*/

    emittedAction(value) {
      if (value.action === "editItem") {
        this.$router.push({
          name: "tech.addons.yearplans.templates.:id",
          params: { id: value.itemId },
        });
      }

      if (value.action === "deleteItem") {
        this.itemId = value.itemId;
        this.itemIndex = value.itemIndex;
        this.deleteDialog = true;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
