  <template>

  <v-dialog v-model="dialogState" max-width="500">
    <v-card>
      <v-toolbar dark color="red" elevation="0">
        
        <v-toolbar-title>{{headline}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('reject')"><v-icon>mdi-close</v-icon></v-btn>
        
      </v-toolbar>
      <v-card-text class="py-4">
        {{message}}
      </v-card-text>

      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="$emit('reject')">OK</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>

</template>

<script>


export default {

  name: 'CustomDialog',

  components: {
    //
  },

  data () {
    return {
      //
    }
  },
  
  props: ['dialog','headline','message'],

  methods: {
    //
  },

  computed: {

    dialogState: {

      get() {
        return this.dialog
      },
      set() {
      }
      
    }
  }

}
</script>

